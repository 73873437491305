import React, { useState, useRef } from 'react';
import COLORS from "../../resources/colors"
import blueLogo from '../../images/clerkie-logo.png';
import formImage from '../../images/image-13.png';

export default function StatsForm(props) { 

    const [firstName, setFirstName] = useState("")
    const [firstNameIsValid, setFirstNameIsValid] = useState(true)
    const [lastName, setLastName] = useState("")
    const [lastNameIsValid, setLastNameIsValid] = useState(true)
    const [email, setEmail] = useState("")
    const [emailIsValid, setEmailIsValid] = useState(true)
    const [comments, setComments] = useState("")
    const [commentsIsValid, setCommentsIsValid] = useState(true)

    //let name = props.name ? props.name : "form-no-name"
    let dialogContent = useRef();

    function formSubmitted(event) { 

        let isValid = dataIsValid()

        if (isValid === false) { 
            event.preventDefault()
        }
    }
   

    function cleanPhoneString(phone) {
        var phoneString = phone
        phone.replace(/" "|"("|")"|"-"|"+"/gi, "");
        return phoneString
      }
      
      function isEmail(email) 
      {
       if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
          return true
        }
          return false
      }
      
      function isPhone(phone) { 
        return (phone.length === 10 || phone.length === 11) 
      }

    function dataIsValid() { 

        let isFirstNameIsValid = firstName !== ""
        setFirstNameIsValid(isFirstNameIsValid) 

        let isLastNameIsValid = lastName !== ""
        setLastNameIsValid(isLastNameIsValid) 
    
        let isEmailValid = isEmail(email)
        setEmailIsValid(isEmailValid)
        
        let isCommentsValid = comments !== ""
        setCommentsIsValid(isCommentsValid)
             
        return isFirstNameIsValid && isLastNameIsValid && isEmailValid && isCommentsValid
    }

    function resetForm() { 
        setFirstName("")
        setFirstNameIsValid(true)
        setLastName("")
        setLastNameIsValid(true)
        setEmail("")
        setEmailIsValid(true)
        setComments("")
        setCommentsIsValid(true)
    }

    function backgroundTapped() { 

        if (props.hideForm != null) { 
            props.hideForm();
        }

        resetForm();
        dialogContent.current.scrollTop = 0
    }

    function containerClicked(event) { 
        event.stopPropagation(); 
    }

    function getImage() { 
        return <div id="statsformimage">

            <div className="StatsImageContainer">
                <div className="StatsFormImage">
                    <img className="StatsFormImage" src={formImage}></img>
                </div>
            </div>
            <style jsx="true">{`
                #statsformimage .StatsImageContainer { 
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                #statsformimage .StatsFormImage { 
                    height: 100%;
                    margin: 0 auto;
                    flex-grow: 0;
                    flex-shrink: 0;
                }

                #statsformimage .StatsFormImage img { 
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }


                @media (max-width: 640px) { 
                   
                    #statsformimage .StatsImageContainer { 
                        display:none;
                    }    
                }

            `}</style>
        </div>
    }

    function getForm() {

        let title = props.title || ""

        return <div id="statsformcontent" ref={dialogContent}>
        <div className="Content">

                <div className="StatsFormTitle">
                    <p>{title}</p>
                </div>
                <div className="StatsFormSubtitle">
                    <p>Fill out the form below to start a conversation</p>
                </div>
            <form 
            name={props.name}
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={formSubmitted}
            >

            <input type="hidden" name={props.name} value="lenders-form" />
                <p hidden>
                <label>
                    Don’t fill this out: <input name="bot-field"/>
                </label>
                </p>
                <div className="SingleItemFormRow"> 
                    <div className="FormItem" style={{
                        gridColumn: 1,
                        gridRow: 1
                    }}>
                
                        <input className={firstNameIsValid ? "" : "invalid"} placeholder="First Name" type="text" name="first_name" id="first_name" value={firstName} onChange={e => setFirstName(e.target.value)}></input>
                    </div>   
                </div>
                <div className="SingleItemFormRow"> 
                    <div className="FormItem" style={{
                        gridColumn: 1,
                        gridRow: 1
                    }}>
                        <input className={lastNameIsValid ? "" : "invalid"} placeholder="Last Name" type="text" name="last_name" id="last_name" value={lastName} onChange={e => setLastName(e.target.value)}></input>
                    </div>   
                </div>
                <div className="SingleItemFormRow"> 
                    <div className="FormItem" style={{
                        gridColumn: 1,
                        gridRow: 1
                    }}>
                        <input className={emailIsValid ? "" : "invalid"} placeholder="Work Email" type="text" name="email" value={email} onChange={e => setEmail(e.target.value)}></input>
                    </div> 
                </div>
                <div className="SingleItemFormRow"> 
                    <div className="FormItem" style={{
                        gridColumn: 1,
                        gridRow: 1
                    }}>
                        <textarea id="comments" className={commentsIsValid ? "" : "invalid"} placeholder="Leave us a note *" name="comments" value={comments} onChange={e => setComments(e.target.value)}>
                            
                        </textarea>
                    </div>
                </div>
                <button type="submit">Send</button>
            </form> 
            </div>
            <style jsx="true">{`
                .StatsForm { 
                    width: 100%;
                    height: 100%;
                }

                #statsformcontent .Content { 
                    font-family: "Helvetica Neue", "Arial";
                    width: 100%;

                    padding: 30px;
                    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                    box-sizing: border-box;
                }

                #statsformcontent form { 
                    margin: 0px;
                }

                #statsformcontent .FormRow {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 0px;
                    margin-bottom: 0px;
                }

                #statsformcontent .SingleItemFormRow {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 0px;
                    margin-bottom: 15px;
                }

                #statsformcontent .FormItem {
                    width: 100%;
                    flex-direction: row;
                    align-items: stretch;
                }

                #statsformcontent .Space {
                    width: 50px;
                }

                #statsformcontent p { 
                    color: ${COLORS.myClerkieBlack};
                    margin: 0px 0px 10px 0px;
                    font-size: 14px;
                    font-weight: 600;
                }

                #statsformcontent label { 
                    color: ${COLORS.myClerkieBlack};
                    margin: 0px 0px 10px 0px;
                    font-size: 14px;
                    font-weight: 600;
                }

                #statsformcontent .Required { 
                    color: red;
                }

                #statsformcontent input { 
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: ${COLORS.white};
                    color: ${COLORS.myClerkieBlack};
                    line-height: 1;
                    padding: 0px 10px;
                    width: 100%;
                    height: 64px;
                    max-width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                    border: 0px solid #aaa;
                    border-radius: 5px;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                    box-sizing: border-box;   
                    
                }

                #statsformcontent input:focus, textarea:focus, select:focus{
                    outline: none;
                }

                ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: ${COLORS.myClerkieBlack};
                    opacity: 1; /* Firefox */
                }
                
                :-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: ${COLORS.myClerkieBlack};
                }
                
                ::-ms-input-placeholder { /* Microsoft Edge */
                    color: ${COLORS.myClerkieBlack};
                }

                #statsformcontent input.invalid { 
                    background-color: ${COLORS.lightMyRedColor};   
                    color: ${COLORS.myRedColor}    
                }

                #statsformcontent select {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${COLORS.myClerkieBlack};
                    line-height: 1;
                    padding: 0px 10px;
                    width: 100%;
                    height: 50px;
                    max-width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                    border: 0px solid #aaa;
                    border-radius: 5px;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    background-image: url(${require('../../images/dropdown-down-arrow.png')}),
                    linear-gradient(to bottom, ${COLORS.white} 0%, ${COLORS.white} 100%);
                    background-repeat: no-repeat, repeat;
                    background-position: right .7em top 50%, 0 0;
                    background-size: .65em auto, 100%;
                }

                #statsformcontent select.invalid { 
                    background-image: url(${require('../../images/dropdown-down-arrow.png')}),
                    linear-gradient(to bottom, ${COLORS.lightMyRedColor} 0%, ${COLORS.lightMyRedColor} 100%);
                    color: ${COLORS.myRedColor}    
                }

                #statsformcontent textarea { 
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: ${COLORS.white};
                    color: ${COLORS.myClerkieBlack};
                    line-height: 1;
                    padding: 10px 10px 0px 10px;
                    width: 100%;
                    height: 75px;
                    max-width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                    border: 0px solid #aaa;
                    border-radius: 5px;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    appearance: none;
                    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                    box-sizing: border-box; 
                }
                
                #statsformcontent textarea.invalid { 
                    background-color: ${COLORS.lightMyRedColor};   
                    color: ${COLORS.myRedColor}    
                }

                #statsformcontent button { 
                    font-size: 14px;
                    font-weight: bold;
                    background-color: ${COLORS.myRedColor};
                    color: ${COLORS.myClerkieBlack}; 
                    padding: 15px 60px;
                    width: 100%;
                    border: 0px solid rgba(255, 255, 255, 1.0);
                    border-radius: 40px;
                    cursor: pointer; 
                    color: white;
                    margin: 20px auto 0px auto;
                    display: block;
                }

                #statsformcontent button:focus { 
                outline: none;
                }

                #statsformcontent .DialogBackground { 
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0.0, 0.0, 0.0, 0.5); 
                    margin: auto;
                    z-index: 100;
                    display: grid; 
                    align-items: center;
                    overflow: hidden;
                }

                #statsformcontent .DialogContainer { 
                    width: 800px;
                    height: auto;
                    background-color: #fafafa; 
                    border-radius: 15px;
                    justify-self: center;
                    position: fixed;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    overflow: hidden;
                }

               

                #statsformcontent .StatsFormTitle { 
                    flex-grow: 0;
                    flex-shrink: 0;
                    padding-top: 0px;
                    padding-bottom: 15px;
                }

                #statsformcontent .StatsFormTitle p { 
                    color: ${COLORS.myClerkieBlack};
                    font-size: 21px;
                    font-weight: 600;
                    width: 100%;
                    text-align: left;
                }

                #statsformcontent .StatsFormSubtitle { 
                    flex-grow: 0;
                    flex-shrink: 0;
                    padding-top: 0px;
                    padding-bottom: 15px;
                }

                #statsformcontent .StatsFormSubtitle p { 
                    color: ${COLORS.myClerkieBlack};
                    font-size: 16px;
                    font-weight: 400;
                    width: 100%;
                    text-align: left;
                }

                @media (max-width: 640px) { 
                    #statsformcontent .DialogContainer { 
                        width: 80%;
                        height: auto;
                    }
                }

                `}</style>
        </div>
    }

    return(
        <div id="statsform" className="StatsForm">

            <div className="DialogBackground" onClick={backgroundTapped}>
                <div className="DialogContainer" onClick={containerClicked}>
                    {getImage()}
                    {getForm()}
                </div>
            </div>

            <style jsx="true">{`

                .StatsForm { 
                    width: 100%;
                    height: 100%;
                }

                #statsform .DialogBackground { 
                    height: 100%;
                    width: 100%;
                    background-color: rgba(0.0, 0.0, 0.0, 0.5); 
                    margin: auto;
                    z-index: 100;
                    display: grid; 
                    align-items: center;
                    overflow: hidden;
                  }
  
                  #statsform .DialogContainer { 
                    width: 660px;
                    height: auto;
                    background-color: #fafafa; 
                    border-radius: 15px;
                    justify-self: center;
                    position: fixed;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    overflow: hidden;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    padding: 0px;
                    column-gap: 0px;
                  }
  
                  @media (max-width: 640px) { 
                   
                      #statsform .DialogContainer { 
                        width: 80%;
                        height: auto;
                        background-color: #fafafa; 
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr;
                        padding: 0px;
                        column-gap: 0px;
                        width: 80%;
                        height: auto;
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;
                      }
                  }
                
            `}</style>
        </div>
    )
}   